<template>
    <div class="grid">
        <div class="col-12">
            <div class="card text-center" v-if="permissionDenied">
                <h4>A kért oldal megtekintéséhez nincs jogosultsága!</h4>
            </div>
            <div class="card" v-if="!permissionDenied">
                <Toast/>
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                        </div>
                    </template>
                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="languages" dataKey="id"
                           :paginator="true" :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['name']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Nyelvek <br> <strong><small>Összesen: {{ total }}</small></strong></h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="name" header="Név" :sortable="true" headerStyle="width:25%; min-width:20rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Név</span>
                            {{ slotProps.data.name }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="slug" header="Slug" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Slug</span>
                            {{ slotProps.data.slug }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Létrehozás dátuma" :sortable="true"
                            headerStyle="width:25%; min-width:20rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Létrehozás dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                    v-tooltip.top="'Megtekintés'" @click="show(slotProps.data)"/>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                    v-tooltip.top="'Szerkesztés'" @click="edit(slotProps.data)"/>
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen nyelv sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="languageDialog" :style="{width: '800px'}" header="Nyelv adatai" :modal="true"
                        class="p-fluid">
                    <div class="field">
                        <label for="name">Név</label>
                        <InputText id="subject" v-model.trim="language.name" required="true"
                                   :class="{'p-invalid': submitted && !language.name}"/>
                        <small class="p-invalid" v-if="submitted && !language.name">Név megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="name">Slug</label>
                        <InputText id="subject" v-model.trim="language.slug" required="true"
                                   :class="{'p-invalid': submitted && !language.slug}"/>
                        <small class="p-invalid" v-if="submitted && !language.slug">Slug megadása kötelező.</small>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveLanguage"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showLanguageDialog" :style="{width: '800px'}" header="Nyelv adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading">
                        <ul class="list-none p-0 m-0 border-top-1 border-300">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">ID</div>
                                <div class="text-900 w-full md:w-5">{{ language.id }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Név</div>
                                <div class="text-900 w-full md:w-5">{{ language.name }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Slug</div>
                                <div class="text-900 w-full md:w-5">{{ language.slug }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Létrehozás dátuma</div>
                                <div class="text-900 w-full md:w-5">{{ language.createdAt }}</div>
                            </li>
                        </ul>
                    </div>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import LanguageService from "@/service/LanguageService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            total: 0,
            languages: null,
            languageDialog: false,
            showLanguageDialog: false,
            language: {},
            filters: {},
            submitted: false,
            loading: true,
            permissionDenied: true,
        }
    },
    languageService: null,
    created() {
        this.languageService = new LanguageService();
        this.initFilters();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));

        if (user.role === 1) {
            this.permissionDenied = true;
        } else {
            this.permissionDenied = false;
            this.getAll()
        }
    },
    methods: {
        getAll() {
            this.languageService.getAll().then(response => {
                this.total = response.data.total
                this.languages = response.data.data
                this.loading = false;
            });
        },
        openNew() {
            this.language = {};
            this.submitted = false;
            this.languageDialog = true;
        },
        edit(language) {
            this.language = {...language};
            this.languageDialog = true;
        },
        hideDialog() {
            this.languageDialog = false;
            this.submitted = false;
        },
        saveLanguage() {
            this.submitted = true;
            this.loading = true;

            if (this.language.name.trim()) {
                this.languageService.create(this.language).then(() => {
                    this.languageDialog = false;
                    this.language = {};
                    this.getAll()

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sikeres művelet!',
                        detail: 'A nyelv mentése sikeres volt.',
                        life: 5000
                    });

                    this.loading = false;
                }).catch(e => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: e.response.data.error,
                        life: 6000,
                    });

                    this.loading = false;
                });
            }
        },
        show(language) {
            this.language = {...language};
            this.showLanguageDialog = true;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Név', key: 'name', width: 20 },
                { header: 'Slug', key: 'slug', width: 20 },
                { header: 'Létrehozás dátuma', key: 'createdAt', width: 20 }
            ];

            const data = this.languages === null ? [] : this.languages;

            data.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_nyelvek.xlsx`;
            a.download = filename;
            a.click();
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'name': {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>
